import React from "react";
import { Container } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import Slide from "react-reveal/Slide";
import Fade from "react-reveal/Fade";
const About = () => {
  return (
    <div>
      <Container>
        {/* <Slide left > */}
        <Row className="mt-5">
          <Col md={5}>
            {/* <GreetingLottie animationPath="/lottie/coding.json" /> */}

            <Slide left>
              <lottie-player
                src="https://assets5.lottiefiles.com/packages/lf20_iv4dsx3q.json"
                background="transparent"
                speed="1"
                style={{ width: "100%", height: "90%" }}
                loop
                autoplay
              ></lottie-player>
            </Slide>
          </Col>

          <Col md={7}>
            <Fade duration={3000}>
              <p className="home-about-body">
                Nama saya <b className="purple">Renjiro Gunawan </b> dengan nama panggilan <b className="purple">Jiro </b>
                <br/>
                  sebagai <b className="purple">Full Stack Developer </b>
                <br />
                <br />
                Saya mahir dalam berbagai bahasa pemrograman seperti &nbsp;
                  <b className="purple">
                    {" "}
                    Python, JavaScript, MySQL, HTML5, dan CSS. {" "}
                  </b>
                <br />
                <br />
                Selain itu, saya mahir juga dalam menggunakan framework dan library seperti &nbsp;

                  <b className="purple">
                    {" "}
                    ReactJS, Flask, bootstrap, tailwind, dan NumPy.{" "}
                  </b>
                <br />

                <br />Di waktu luang, saya senang mempelajari teknologi baru dan &nbsp;
                  <b className="purple">membangun teknologi web serta produk-produk baru.  </b>
              </p>
            </Fade>
          </Col>
        </Row>
        {/* </Slide> */}
      </Container>
    </div>
  );
};

export default About;
